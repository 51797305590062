import React, { useState } from 'react';
import { useSprings, animated, interpolate } from 'react-spring';
import { useGesture } from 'react-use-gesture';
import { shuffle } from 'lodash-es';
import Card from './Card';
import './Deck.css';

const cards = [
  '70% of facts are true',
  '70% of Americans are overweight',
  'To the cloud!',
  '70% of people are water',
  'A fully grown Tony can eat its own body weight in just less than 24 hours',
  'Tony and Nigel Farage have never been observed in the same place at the same time',
  'Alpacas are herd animals. A lone alpaca will eventually die',
  "Tonys can't look up",
  'Always stay upwind of a Tony during mating season ',
  "If you laid all of the Tony's in the world end to end, you'd probably catch something",
  "Eskimos have twenty words for 'Tony'",
  'In space, no one can hear you say Tony',
  "The imaginary musical 'Lease' has never won a Tony award",
  'A Tony has bilateral but not rotational symmetry',
  'Cucumbers should not be stored in the fridge because they are mostly water',
];

// These two are just helpers, they curate spring data, values that are later being interpolated into css
const to = i => ({
  x: 0,
  y: i * -4,
  scale: 1,
  rot: -10 + Math.random() * 20,
  delay: i * 100,
});
const from = i => ({ x: 0, rot: 0, scale: 1.5, y: -1000 });
// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r, s) =>
  `perspective(1500px) rotateX(30deg) rotateY(${r /
    10}deg) rotateZ(${r}deg) scale(${s})`;

function Deck() {
  const [order, setOrder] = useState(shuffle([...Array(cards.length).keys()]));
  const [gone] = useState(() => new Set()); // The set flags all the cards that are flicked out
  const [props, set] = useSprings(cards.length, i => ({
    ...to(i),
    from: from(i),
  })); // Create a bunch of springs using the helpers above
  // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
  const bind = useGesture(
    ({
      args: [index],
      down,
      delta: [xDelta],
      distance,
      direction: [xDir],
      velocity,
    }) => {
      const trigger = velocity > 0.2; // If you flick hard enough it should trigger the card to fly out
      const dir = xDir < 0 ? -1 : 1; // Direction should either point left or right
      if (!down && trigger) gone.add(index); // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
      set(i => {
        if (index !== i) return; // We're only interested in changing spring-data for the current spring
        const isGone = gone.has(index);
        const x = isGone ? (200 + window.innerWidth) * dir : down ? xDelta : 0; // When a card is gone it flys out left or right, otherwise goes back to zero
        const rot = xDelta / 100 + (isGone ? dir * 10 * velocity : 0); // How much the card tilts, flicking it harder makes it rotate faster
        const scale = down ? 1.1 : 1; // Active cards lift up a bit
        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
        };
      });
      if (!down && gone.size === cards.length)
        setTimeout(
          () =>
            gone.clear() ||
            set(i => to(i)) ||
            setOrder(shuffle([...Array(cards.length).keys()])),
          600
        );
    }
  );
  // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
  return props.map(({ x, y, rot, scale }, i) => (
    <animated.div
      key={i}
      className="card-container"
      style={{
        transform: interpolate(
          [x, y],
          (x, y) => `translate3d(${x}px,${y}px,0)`
        ),
      }}
    >
      {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
      <animated.div
        {...bind(i)}
        className="card"
        style={{
          transform: interpolate([rot, scale], trans),
        }}
      >
        <Card content={cards[order[i]]} />
      </animated.div>
    </animated.div>
  ));
}

export default Deck;
